import React from "react";
import { Link } from "react-router-dom";

import hadafBG from "../../assets/images/hadaf-BG.png"
import hadafLogo from "../../assets/images/hadaf-Logo.png"

import hadafFootballDesing from "../../assets/images/hadaf-football-design.png"

const HadafLanding = () => {
  return (
    <div style={{ backgroundImage: `url(${hadafBG})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', height: '100vh', width: '100%' }}>

      <div style={{ display: 'flex', height: '80%', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ padding: '10px 60px', height: '100%', alignContent: 'center', position: 'relative' }}>
          <img src={hadafLogo} alt="hadaflogo" style={{ maxWidth: '100%' }} />
          <h1 style={{ fontSize: '35px', color: 'white', }}>Play games <br /> and win exciting prizes!</h1>
          <div style={{ position: 'absolute', bottom: '60px', color: 'yellow' }}>
            <Link to="/hadaf/tnc" style={{ textDecoration:'underline', color: 'yellow' }}>
              Terms of Service
            </Link>

           
            <span style={{ color: 'yellow' }}> & </span>
            <Link to="/hadaf/privacy" style={{ textDecoration:'underline', color: 'yellow' }}>
              Privacy Policy
            </Link>
          </div>
        </div>
        <img src={hadafFootballDesing} alt="" style={{ height: '100%' }} />

      </div>

    </div>
  );
};

export default HadafLanding;
