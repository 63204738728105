import React from "react";

const TnC = () => {
  return (
    <div style={{padding: '16px'}}>
      <h3>TERMS OF SERVICE</h3>
      <p>These user terms and conditions (“T&Cs”) apply to Your access to, and use of the online services including mobile applications and websites (“Services”), provided by Arkimy Technologies Private Limited, and its subsidiaries, licensees, and affiliated companies (“Arkimy”). All mobile applications and websites that Arkimy uses to provide its Services shall be referred to cumulatively and individually as “Platform”.</p>
      <p>This document is an electronic record in terms of the Information Technology Act, 2000 and rules thereunder as applicable and the provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures. By continuing usage of the Services, You are consenting to be bound by these T&Cs for use and access of the Services. PLEASE ENSURE THAT YOU READ AND UNDERSTAND ALL THESE T&Cs BEFORE YOU USE ANY OF THE SERVICES, BECAUSE YOU SHALL BE BOUND BY ALL THE TERMS AND CONDITIONS CONTAINED HEREIN. If You do not accept any of these T&Cs, then please don’t continue usage or avail any of the Services. YOUR AGREEMENT TO THESE T&Cs SHALL OPERATE AS A BINDING AGREEMENT BETWEEN YOU AND ARKIMY IN RESPECT OF THE SERVICES.</p>
      <p>By accepting these T&Cs, You also allow Arkimy to send you promotional emails and SMS alerts from time to time.</p>

      <h3>DEFINITIONS</h3>
      <p>All of the defined and capitalised terms in these T&Cs will have the meaning assigned to them here below:</p>
      <ul>
        <li>“Account” or “User Account” or “Profile” refers to the account created by You on the Services to continue usage of the Services. This account retains and shows all details you share with Arkimy.</li>
        <li>“Applicable Laws” shall mean and include all applicable statutes, enactments, acts of legislature or parliament, laws, ordinances, rules, by-laws, regulations, notifications, guidelines, policies, directions, directives and orders of any governmental authority, tribunal, board, or a court of India.</li>
        <li>“Email ID” shall mean Your current, active and valid email ID.</li>
        <li>“Arkimy/ We/ Our/ Us” shall mean Arkimy Technologies Private Limited, a company registered under the Companies Act, 2013 and having its registered office at 2nd floor, Unit No. 202, Vipul Business Park, Sohna Road, Sector 48, Gurugram, Haryana.</li>
        <li>“Service(s)” shall mean the services, including mobile applications and websites provided by Arkimy. Services may be used even by users who have not created an Account on the Platform or Registered themselves and are merely using the Platform.</li>
        <li>“You”/“Your “/“Yourself”/”User” shall mean and reference any user accessing or using the Services.</li>
      </ul>

      <h3>INTERPRETATION</h3>
      <ul>
        <li>Any reference to the singular includes a reference to the plural and vice versa, unless explicitly provided for otherwise; and any reference to the masculine includes a reference to the feminine and vice versa.</li>
        <li>Headings and captions are used for convenience only and will not affect the interpretation of these T&Cs.</li>
        <li>Any reference to a natural person will, unless repugnant to the context, include his legal heirs, executors and permitted assignees. Similarly, any reference to a juristic person such as Arkimy will, unless repugnant to the context, include its affiliates, successors and permitted assignees.</li>
      </ul>

      <h3>ELIGIBILITY</h3>
      <ul>
        <li>You will be "Eligible" to use the Services only when You fulfil all of the following conditions:</li>
        <ul>
          <li>You have attained at least 18 (eighteen) years of age or You have attained 13 (thirteen) years of age and your usage is after review of the app and its T&Cs by a guardian above 18 (eighteen) years of age who has consented to such usage. The determining age would depend on the jurisdiction you belong to, however, applicability of laws would be as per the Indian laws.</li>
          <li>You are competent to enter into a contract under the Applicable Laws.</li>
        </ul>
        <li>You may avail the Services only if You fulfil the conditions as mentioned above. If You are not eligible, please immediately abandon any and all attempts to register with Us and/or use or access Our Services in any manner whatsoever, directly or indirectly.</li>
        <li>You acknowledge that We rely completely on the information provided by You and We shall not be held liable, in any manner, if You or anyone who uses Your Account to access the Services is not eligible to use the same and/or has provided us with incorrect, inaccurate, untrue details or information.</li>
      </ul>

      <h3>LIMITED LICENCE</h3>
      <p>Subject to your agreement of and continuing compliance with these T&Cs and any other relevant Arkimy policies, Arkimy grants you a non-exclusive, non-transferable, revocable limited licence subject to the limitations in these T&Cs to access and use the Service using a Arkimy supported web browser (such as Mozilla Firefox or Microsoft Internet Explorer) or mobile device solely for your own non-commercial entertainment purposes. You agree not to use the Service for any other purpose, directly or indirectly, in any manner whatsoever.</p>
      <p>You understand that while at times you may “earn” "buy" or "purchase" (a) virtual currency, including but not limited to virtual coins, cash, tokens, or points, all for use in the Service; or (b) virtual in-game items (together with virtual currency, "Virtual Items"); these real world terms are only being used as shorthand. You do not in fact "own" the Virtual Items and the amounts of any Virtual Item do not refer to any credit balance of real currency or its equivalent. Rather, you purchase a limited licence to use the Service, including software programs that occasionally manifest themselves as these items. The purchase and sale of the limited licence referred to in these T&Cs is a completed transaction upon receipt of your direct payment or redemption of a Arkimy game card or a third party virtual currency like Facebook Credits. ALL PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH THE SERVICE ARE FINAL AND NON-REFUNDABLE. Arkimy holds no liability for any such currency and/or value of it and the same can only and solely be used on the Platform for defined and described Services. Arkimy may limit the total amount of Virtual Items that may be purchased at any one time, and/or limit the total amount of Virtual Items that may be held in your account in the aggregate.</p>
      <p>Arkimy has the right to offer, modify, eliminate, and/or terminate Virtual Items, and/or the Services, or any portion thereof, at any time, without notice or liability to you. If we discontinue the use of Virtual Items, and you have an active application on your device, we will attempt to provide notice to you through the Services or through other communications. In case you do not have an active application, at the time of an update or feature notification, you may not receive the intimation of such update or notification when you start using the application again.</p>

      <h3>REGISTRATION AND ACCOUNT</h3>
      <ul>
        <li>You understand and acknowledge that You can register on the Services or create an Account only after complying with the requirements above and through verification means that the Company decides to put in place. For now, the Company is allowing creation of account through a valid Email ID or a social networking site ID or Device ID of User; the User may only be allowed a Guest Account using their Device ID which may or may not have access to limited features on the platform.</li>
        <li>You will be required to enter Your Email ID or social networking site ID on the Services following which you will be registered. We will be storing this information with us for verification and other purposes.</li>
        <li>Upon completion of the verification process in accordance with the above, You will be granted access to the Services.</li>
        <li>In order to use the Services, You are required to provide Registration Data to Arkimy. You shall not impersonate someone else or provide Account information, an email address or any other information that is not Your own. We shall bear no liability for false, incomplete, old or incorrect Registration Data provided by You.</li>
        <li>In the event of any change in the said Registration Data, You agree to promptly update Your Registration Data on the Account so as to ensure that the communication We intend to send to You is promptly delivered to You and is not sent to any other entity/third party. This is solely Your liability and Arkimy shall not be liable for the same.</li>
        <li>Arkimy will store the Registration Data provided by You for contacting You for all Service related matters. You may promptly inform us of any change in the Registration Data provided.</li>
        <li>We may send any Information to You to the mobile number or Original ID registered with Us as Registration Data.</li>
        <li>We reserve the right to suspend or terminate Your Account with immediate effect and for an indefinite period, if We have a reason to believe that the Registration Data or any other data provided by You is incorrect or false, or that the security of Your Account has been compromised in any way, or for any other reason as We deem fit.</li>
        <li>It is Your responsibility to check to ensure You download the correct mobile application for Your device. We are not liable if You do not have a compatible mobile device or if You download the wrong version of the application for Your mobile device. We reserve the right to terminate the Services should You be using the Service with an incompatible or unauthorized device.</li>
        <li>You will not assign or otherwise transfer Your Account to any third party. In case you do so, you shall be solely liable for any issues caused due to this.</li>
        <li>In case of any unauthorised use of Your Account, You are to bring the same to Our notice promptly. In the event of such unauthorised use, please immediately reach Us at <a href="mailto:support@hadaf11.com">support@hadaf11.com</a>. We shall not be liable for any unauthorised or unsolicited use of your account in any manner whatsoever.</li>
        <li>In case, You are unable to access Your Account, please immediately inform Us at <a href="mailto:support@hadaf11.com">support@hadaf11.com</a> and make a written request for blocking Your Account.</li>
      </ul>

      <h3>USE OF SERVICES</h3>
      <p>Use of Our Services & Virtual Items: You may purchase Virtual Items on our Platform using fiat currency and third party payment platforms. Our Platform is integrated with authorised and legitimate third party payment platforms using which you can make the payments for Virtual Items on Our Platform. Virtual Items can only be used on the Platform and not outside the Platform, in any manner. Virtual Items are also not directly transferable to any third party/other users unless expressly allowed by the Platform or Service.</p>
      <p>Use of Our Services & Social Interactions: You may interact with other Users and meet new people (Other Users), add friends (Other Users) and communicate with each other (Other Users) through either chat over text, images (including gifs), audio and video messages. You shall use these Services as per this Policy and in accordance with the laws, rules and standards laid down herein.</p>
      <p>You agree, undertake and confirm that Your use of the Services shall be strictly governed by the following binding principles:</p>
      <ul>
        <li>You may use the Services for lawful purposes only.</li>
        <li>You shall not host, display, upload, modify, publish, transmit, update or share any information that:</li>
        <ul>
          <li>belongs to another person and to which You do not have any right to; or that interferes with another user's use and enjoyment of the Services or any other individual's use and enjoyment of similar services; or</li>
          <li>is harmful, harassing, blasphemous, defamatory, obscene, pornographic, libelous, invasive of another's privacy, hateful, or ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever, or unlawfully threatening or unlawfully harassing another user, the same being qualified to include behavior termed as "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986,; or</li>
          <li>is misleading in any way; or          </li>
          <li>is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, pedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual; or</li>
          <li>involves the transmission of "junk mail," "chain letters," or unsolicited mass mailing or "spamming"; or</li>
          <li>infringes upon or violates any third party's rights, [including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorised disclosure of a person's name, email address, physical address or phone number)]; or</li>
          <li>contains restricted or password-only access pages, or hidden pages or images (those not linked to the Services or from another accessible page); or</li>
          <li>provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone; or</li>
          <li>provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses; or</li>
          <li>contains video, photographs, or images of another person without his or her express written consent and permission, or the permission or the consent of his her guardian in the case of a minor; or</li>
          <li>tries to gain unauthorised access or exceeds the scope of authorised access (as defined herein and in other applicable codes of conduct or end user access and licence agreements) to the Services or to profiles, blogs, communities, Account information, bulletins, friend request, or other areas of the Services or solicits passwords or personal identifying information for commercial or unlawful purposes from other users; or</li>
          <li>engages in commercial activities and/or sales without Our prior written consent, including activities such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of "virtual" items. [Throughout these T&Cs, Our "prior written consent" means a communication coming from Our authorised representative, specifically in response to Your request, and specifically addressing the activity or conduct for which You seek authorization]; or</li>
          <li>refers to any website, app or URL that, in Our sole opinion, contains material that is inappropriate for the Services, or contains content that would be prohibited or violates the letter or spirit of these T&Cs; or</li>
          <li>harms minors in any way; or</li>
          <li>is fraudulent or involve the sale of counterfeit or stolen items; or</li>
          <li>violates any law for the time being in force; or</li>
          <li>deceives or misleads the addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature; or</li>
          <li>exhibits the tenor of impersonating another person; or</li>
          <li>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any Trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal identifiable information or attempt to interfere with the proper working of the Services or any transaction being conducted on the Services; or</li>
          <li>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting to any other nation; or</li>
          <li>is false, inaccurate or misleading; or</li>
          <li>directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any Applicable Law for the time being in force; or</li>
          <li>creates any liability for Us or causes Us to lose (in whole or in part) the Services of Our ISPs or other suppliers.</li>
        </ul>
        <li>You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Services Or any content, or in any way reproduce or circumvent the navigational structure or presentation of the Services or any content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Services. We reserve the right to bar any such activity.</li>
        <li>You shall not copy, distribute or disclose any part of the Services in any medium.</li>
        <li>You shall not attempt to interfere with, compromise the system integrity or security or decipher any transmissions to or from the servers running the Services.</li>
        <li>You shall not take any action that imposes, or may impose, at Our sole discretion, an unreasonable or disproportionately large load on Our infrastructure.</li>
        <li>You shall not collect or harvest any personally identifiable information, including Account names, from the Services.</li>
        <li>The Services are being licensed to You for use for specific purposes only, You shall not use the Services for any other purpose or for commercial purposes without having all necessary rights and licences to the Content available on the Services.</li>
        <li>You shall not impersonate another person or otherwise misrepresent Your affiliation with a person or entity, conducting fraud, hiding or attempting to hide Your identity.</li>
        <li>You shall not interfere with the proper working of the Services.</li>
        <li>You shall not access any content on the Services through any technology or means other than those capabilities provided by the Services.</li>
        <li>You shall not bypass the measures We may use to prevent or restrict access to the Services, including without limitation features that prevent or restrict use or copying of any content or enforce limitations on use of the Services or the content therein.</li>
        <li>You shall not probe, scan or test the vulnerability of the Services or any network connected to the Services nor breach the security or authentication measures on the Services or any network connected to the Services. You may not reverse look-up, trace or seek to trace any information on any other user of or visitor to Services, or any other user of Arkimy, including any of Our account not owned by You, to its source, or exploit the Services or any service or information made available or offered by or through the Services, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than Your own information, as provided for by the Services.</li>
        <li>You agree that You will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Services or Our systems or networks, or any systems or networks connected to the Services.</li>
        <li>You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal You send to Us on or through the Services.</li>
        <li>You shall not use the Services or any Content for any purpose that is unlawful or prohibited by these T&Cs as well as the Applicable Law and/ or to solicit the performance of any illegal activity or other activity that infringes the rights of Arkimy or others.</li>
        <li>You shall not use the Services in any manner that could damage, disable, overburden, block or impair any of the servers connected to the Services. You may not attempt to gain unauthorised access to the Services through hacking, password mining or any other means.</li>
        <li>You shall not reverse engineer, decompile and disassemble, copy, reproduce, reuse any software used to provide the Services.</li>
        <li>You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about Us or the brand name or any domain name used by Us or otherwise engage in any conduct or action that might tarnish the image or reputation of Arkimy, or otherwise tarnish or dilute any of Our trade or service marks, trade name and/or goodwill associated with such trade or service marks, trade name as may be owned or used by Us.</li>
        <li>Solely to enable Us to use the information so that We are not violating any rights You might have with respect to Your information, You agree to grant Us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights You have in Your information, in any media now known or not currently known, with respect to Your information. We will only use Your information in accordance with these T&Cs and Our privacy policy (which is incorporated herein by reference).</li>
        <li>You shall not engage in advertising to, or solicitation of, other users of the Services to buy or sell any products or services, including, but not limited to, services being displayed on or related to the Services. You may not transmit any chain letters or unsolicited commercial or junk email to other users via the Services. It shall be a violation of these T&Cs to use any information obtained from the Services in order to harass, abuse, or harm another person, or in order to contact, advertise, solicit, or sell to another person outside of the Services without Our prior explicit consent. In the event of Us according consent to a person to advertise through Our Services, We may, in order to protect Our users from such advertising or solicitation, reserve the right to restrict the number of messages or emails which a user may send to other users in any 24-hour period, the same being subject to Our sole discretion.</li>
        <li>You understand that we have the right at all times to disclose any information (including the identity of the users) as necessary to satisfy the Applicable Law, or valid governmental request or request from any authorities. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or summon. In addition, We can (and You hereby expressly authorise Us to) disclose any information about You to law enforcement or other government officials, as We, in Our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury.</li>
        <li>We reserve the right, but have no obligation, to monitor the materials posted on the Services. We shall have the right to remove or edit any Content that, in Our sole discretion violates, or is alleged to violate, any Applicable Law or the spirit or letter of these T&Cs, our policies or simply to the best of our judgement. Notwithstanding this right of Arkimy, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE SERVICES AND IN YOUR PRIVATE MESSAGES. Please be advised that such content posted does not necessarily reflect Our views. In no event shall We assume or have any responsibility or liability for any content posted or for any claims, damages or losses resulting from the use of the content and/or appearance of content on the Services. You hereby represent and warrant that You have all necessary rights in and to all content You provide and all the information it contains, and that such content shall not infringe any proprietary or other rights of third parties, and shall not contain any libellous, tortious, or otherwise unlawful information</li>
        <li>We shall have all the rights to take necessary action and claim damages that may occur due to Your involvement/participation in any way on Your own or through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services).</li>
      </ul>

      <h3>CONTENT POSTED ON SERVICES AND INTELLECTUAL PROPERTY</h3>

      <ul>
        <li>All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code (collectively, "Content"), including but not limited to the design, structure, selection, coordination, expression, "look and feel" and arrangement of such Content, contained on the Services/Platform is owned, controlled or licensed by or to Us, and is protected by trade dress, copyright, patent and trademark laws, and various other intellectual property rights and unfair competition laws, and may not be copied, imitated or used, in whole or in part, without the prior written permission of Arkimy, in any manner whatsoever.</li>
        <li>Arkimy owns, has licensed, or otherwise has rights to use all of the content that appears in the Service. Notwithstanding any provision to the contrary herein, you agree that you:</li>
        <ul>
          <li>Are using the Services and Platform merely for the purpose of limited use and have no further rights on either the Service, the Platform or any of its elements, features, etc.</li>
          <li>have no right or title in or to any content that appears in the Service, including without limitation the Virtual Items appearing or originating in any Arkimy game (Service), whether “earned” in a game or “purchased” from Arkimy, or any other attributes associated with an Account or stored on the Service.</li>
        </ul>
        <li>Arkimy prohibits and does not recognize any purported transfers of Virtual Items effectuated outside of the Service, or the purported sale, gift or trade in the "real world" of anything that appears or originates in the Service, unless otherwise expressly authorised by Arkimy in writing. Accordingly, you may not sublicense, trade, sell or attempt to sell in-game Virtual Items for "real" money, or exchange Virtual Items for value of any kind outside of a game, without Arkimy’s written permission. Any such transfer or attempted transfer is prohibited and void, and will subject your Account to termination.</li>
        <li>Except as expressly provided in these T&Cs, no part of the Services and no Content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including "mirroring") to any other computer, server, website or other medium for publication or distribution or for any commercial enterprise, without Our express prior written consent</li>
        <li>You may use information on Our Services that has been made available by Us for downloading from the Services, provided You:</li>
        <ul>
          <li>do not remove any proprietary notice language in all copies of such documents/ information; or</li>
          <li>use such information only for Your personal, non-commercial informational purpose and do not copy or post such information on any networked computer or broadcast it in any media; or</li>
          <li>make no modifications to any such information; or</li>
          <li>do not make any additional representations or warranties relating to such documents/ information.</li>
        </ul>
        <li>All trademarks, brands and service marks used in connection with the Services offered are owned by Arkimy and are Arkimy's property. Arkimy owns all copyrights and intellectual property rights and database rights in connection with the Services. For use of any third party's intellectual property, You need to get permission directly from the owner of the intellectual property for any use. Third party trademarks may appear on the Services and all rights therein are reserved to the registered owners of those trademarks.</li>
        <li>You agree to abide by laws pertaining to copyright, trademark, patent, and trade secret ownership and use of intellectual property, and You shall be solely responsible for any violations of any Applicable Laws and for any infringements of any intellectual property rights caused by use of the Services</li>
        <li>You shall be responsible for any notes, messages, e-mails, photos, drawings, profiles, opinions, ideas, images, videos, audio files or other materials or information posted or transmitted by You on the Services (“Posted Content”). Such Posted Content will become Our property and You grant Us the royalty-free, sub-licensable, transferable, perpetual, irrevocable, non-exclusive, worldwide license to use, reproduce, modify, publish, list information regarding, edit, translate, distribute, syndicate, publicly perform, publicly display, and make derivative works of all such Posted Content, in whole or in part, and in any form, media or technology, whether now known or hereafter developed, for use in connection with the Services and Arkimy’s business. We shall be entitled to use the Posted Content or any of its elements for any type of use forever, including but not limited to promotional and advertising purposes and in any media, whether now known or hereafter devised, including the creation of derivative works that may include Posted Content. You agree that any Posted Content may be used by Us in the manner that We deem fit, consistent with Applicable Laws and You are not entitled to any payment or other compensation for such use of Posted Content by Us. Arkimy takes no responsibility and assumes no liability for the Posted Content that You or any other user or third party creates, stores, shares, posts or sends through the Services. You shall be solely responsible for Your Posted Content and the consequences of posting, publishing it, or sharing it and You agree that We are only acting as a passive conduit for Your online distribution and publication of Your Posted Content. Notwithstanding anything contained in these T&Cs, if Your Posted Content violates these T&Cs, You shall bear all legal responsibility and obligation for that content.</li>
        <li>In the event that You violate these guidelines We may take requisite enforcement steps against You including but not limited to issuing warnings; removing content, suspending or removing the accounts and/or servers responsible, and potentially reporting them to law enforcement.</li>
      </ul>

      <h3>THIRD PARTIES/ OTHER BUSINESSES</h3>
      <ul>
        <li>IAP: Users can purchase Virtual Items in the game through In-App Purchase. Our current Platforms are Google (Play Store), Apple (App Store) etc. The Platform processes payments using the User’s payment method on file, and does not share credit card info/wallet details with our app/company. The Platform does not support any payment methods outside the Platform’s native In-App purchase. We presume that all purchases made using your account are made and authorised by you. Since we cannot control the use and access of the account at our end, it is your responsibility to ensure its safety and maintain the security on your account.</li>
        <li>Arkimy’s Services may include advertising, which may be targeted to you based on your Account Information, and other information. Such advertising may include links to third party websites. If you choose to review or purchase any advertised products or services (for example, by clicking on the applicable link), you may be taken to a third party website where you can review the selected product(s) or services and potentially purchase such product(s) or services.</li>
        <li>Ads: Users may be viewing ads while using the Services and be taken on to third party Platforms, and accept and agree that they are doing so only of their own accord.</li>
        <li>We do not take responsibility or liability for the actions, products, content on the Services, which are linked to affiliates and/or third party websites using Our APIs or otherwise. In addition, We may provide links to third party websites of affiliated companies and certain other businesses for which, We assume no responsibility for examining or evaluating the products and services offered by them, and We do not warrant the offerings of, any of these businesses or individuals or the content of such third party website(s). We do not in any way endorse any third party website(s) or content thereof. Your reference to and use of the same, shall be solely at Your own risk.</li>
        <li>You are solely responsible for your interactions with other users of the Service and any other parties with whom you interact through the Service and/or Arkimy games. Arkimy reserves the right, but has no obligation, to become involved in any way with these disputes.</li>
        <li>If you decide to purchase any such product(s) or services, from any third party, this will be a separate and independent transaction between you and the provider of such product(s) or services which is not controlled by Arkimy and is not subject to this Terms of Use or our Privacy Policy.</li>
        <li>The inclusion of any link to third-party sites or advertisements or promotions of third party products or services does not imply endorsement by Arkimy. Arkimy is not responsible for the products and services offered by or on third-party sites.</li>
        <li>We encourage you to seek independent professional advice if you have any questions or concerns before purchasing third party products or services that are advertised or promoted on Arkimy’s Platform.</li>
        <li>WHEN YOU CLICK ON A LINK FOR A THIRD PARTY SERVICE OR PRODUCT ADVERTISED ON ARKIMY’s PLATFORM TO LEARN MORE ABOUT OR PURCHASE SUCH PRODUCT OR SERVICE, YOUR CONSENT TO US TRANSMITTING YOUR PERSONAL AND FINANCIAL INFORMATION TO THE THIRD PARTY ADVERTISING SUCH SERVICE OR PRODUCT IN ORDER TO ASSIST SUCH THIRD PARTY IN ADVISING YOU WITH RESPECT TO THE ADVERTISED PRODUCT OR SERVICE.</li>
        <li>All applicable policies, terms and conditions, etc. of the third party platforms shall be applicable on you in case you choose to access their platform and/or services in any manner whatsoever.</li>
      </ul>

      <h3>LINKS</h3>
      <ul>
        <li>You must not use, on Your site or in any other manner, any trademarks, service marks or any other materials appearing on the Services/Platform, including any logos or characters, without Our express written consent and the consent of the owner of the mark or materials.</li>
        <li>You must not frame or otherwise incorporate into another third party website or present in conjunction with or juxtaposed against such a website any of the content or other materials on the Services, without Our prior written consent.</li>
      </ul>

      <h3>FEES AND PURCHASE TERMS</h3>
      <ul>
        <li>In the Service you may purchase, with "real world" money, a license to use Virtual Items and or other goods or services. You may also obtain a license to use Virtual Items by redeeming Arkimy game cards or third party virtual currency, such as Facebook Credits.</li>
        <li>PURCHASES OR REDEMPTIONS OF THIRD PARTY VIRTUAL CURRENCY TO ACQUIRE A LICENSE TO USE VIRTUAL ITEMS ARE NON-REFUNDABLE.</li>
        <li>You can license Virtual Items by visiting the purchase page in one of our games, providing your billing information, confirming the particulars of your purchase and re-affirming your agreement to these Terms. When you purchase a license to Virtual Items from our Service, We may send you a confirmatory e-mail that will contain details of the items you have ordered. Please check that the details in the confirmatory e-mail are correct as soon as possible and maintain a copy of it for your records. Arkimy keeps records of transactions in order to deal with any subsequent queries. For instance, you purchase Credits from third party apps/stores such as Facebook, Google Play Store and Apple App Store etc., you are agreeing to their Payment Terms and Arkimy is not a party to the transaction. You are requested to read their Payment Terms before making any transaction.</li>
        <li>For Virtual Items, your order will represent an offer to us to purchase a license for the relevant service(s) which will be accepted by us when We make the Virtual Items available in your account for you to use in our games or debit your credit card, whichever comes first.</li>
        <li>Your license to Virtual Items for use in Arkimy games is a service provided by Arkimy that commences upon acceptance by Arkimy of your purchase or redemption of third party virtual currency. By ordering a license to use Virtual Items you agree and accept that Arkimy will provide it to you promptly following completion of your purchase. If you reside in the European Union and you purchase a product or service from Arkimy, you may have the right to withdraw from a purchase within seven calendar days, commencing on the day after the date of purchase (the "Cooling Off Period"). However, you lose your right of withdrawal if the performance of the services begins before the end of the Cooling Off Period. Accordingly, please note that if you purchase a license to use Virtual Items from Us, your right of withdrawal is lost as the performance of our services begins promptly once your purchase is completed</li>
        <li>You agree to pay all fees and applicable taxes incurred by you or anyone using an Account registered to you. Arkimy may revise the pricing for the goods and services it licenses to you through the Service at any time.</li>
      </ul>

      <p>YOU ACKNOWLEDGE THAT ARKIMY IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL ITEMS WHEN AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY.</p>
      <ul>
        <li>Certain minimums may apply to purchases of Virtual Items and certain maximums may apply depending on your transaction method. Arkimy reserves the right to change the maximum and minimum amounts applicable to purchase the Virtual Items at any time without notice, consistent with applicable law.</li>
        <li>Arkimy may provide you with an option in certain Services wherein you may be allowed to transfer the permitted Virtual Items owned by you to other users. However, this option may only be granted by Arkimy upon their discretion, who also holds the right to make any changes in this option including but not limited to the type of Virtual Items which can be transferred, the limit on such transfer, the accounts to which it can be transferred and the manner in which the Virtual Items can be transferred. The decision of Arkimy will be final and no request for any review/appeal will be entertained.</li>
        <li>You may not sublicense, trade, sell, or attempt to sell Virtual Items for real money, or exchange Virtual Items for value of any kind outside of the Services, without Arkimy’s written permission. Any such transfer or attempted transfer is prohibited and void and will subject your account to termination.</li>
        <li>The ownership of these Virtual Items shall belong to you on the Platform and can only be transferred IN-APP to another registered User on the Platform. However, at all times Arkimy owns all vested and inherent IPs and proprietary rights in such Virtual Items.</li>
      </ul>

      <h3>REWARDS</h3>
      <ul>
        <li>Arkimy may offer one or more rewards (“Rewards”) through specific activities such as advertisements, games, surveys, quizzes etc. (“Activities”), wherein the users may be able to either earn points, which can be redeemed for Rewards or the users may directly receive the Rewards.</li>
        <li>Not all of Arkimy’s Activities will carry Rewards. Some of the Activities may include additional terms that apply to your participation in the Activities allowing you to earn points or Rewards. Points awarded have no cash value.</li>
        <li>Arkimy may limit, suspend or terminate Your ability to participate in Activities in its sole and absolute discretion, and may void any points, Rewards, or potential Rewards you may have earned or accumulated, if Arkimy determines in its sole discretion that You have not complied with these Terms or any additional terms applicable to such participation. The decision tendered by Arkimy in this regard will be final and binding on You.</li>
        <li>Arkimy reserves the right to change, suspend, or cancel all or a portion of the Activities, including any points or Rewards You may have accrued, at any time. Arkimy will generally use reasonable efforts to provide notice to You of any material change to or a suspension or cancellation of the Activities; however, this may not always be possible.</li>
        <li>There may be limitations on Activities and Rewards, so please be sure to review all applicable additional terms before deciding whether or not you would like to participate. Arkimy reserves the right to amend these limitations at any time without giving any notice.</li>
        <li>If You attempt to redeem points for a reward and Arkimy determines that the reward is unavailable, out of stock, or for whatever reason cannot be provided to You, Arkimy may, at its sole and absolute discretion, provide you with a reward of equal or greater value. No credit, reversal, or refund of points will be issued for any reason after points have been redeemed; in other words, once you order a reward, you may not cancel the reward or return the reward for a refund of points.</li>
        <li>Rewards and/or earned points are non-transferable, may not be bartered or sold, and are void if a transfer is attempted.</li>
        <li>Any Account that has not been logged into and points either earned or redeemed for one year or more may be deemed inactive and the Account closed. We may either close the Account completely or remove any points in the Account and treat it as inactive, as we elect.</li>
        <li>Any points or Rewards that have not been redeemed for two (2) years from the time such points were earned or awarded may be deemed expired and removed from your Account. Arkimy will not recredit any earned points or re-offer you the reward so earned.</li>
        <li>We may modify the foregoing points expiration rules and policies at any time.</li>
      </ul>

      <h3>THIRD PARTY RESOURCES</h3>
      <ul>
        <li>Arkimy is not responsible for the availability or quality of third party services, including cell phone networks, hotspots, wireless internet and other services. Such third party services may affect your ability to utilize the Services or participate in an Event and you hereby waive and release Niantic and any other party involved in creating or delivering the Services from all claims, demands, causes of action, damages, losses, expenses or liability which may arise out of, result from, or relate in any way to such third party services.</li>
        <li>Arkimy shall also not be liable in case the User creates their account using third party platforms or social media platforms and their social media platforms get hacked or face a security breach.</li>
      </ul>

      <h3>HACKING AND SECURITY BREACHES</h3>
      <ul>
        <li>Hackers or other malicious groups or organisations may attempt to interfere with Your Account in a variety of ways, to gain access to Your Account and ultimately the information which is in Your Account including the Your financial information. You are solely responsible to keep your Account Information such as username, password etc. safe and confidential by adopting and practising safety measures such as not accessing the Platform from public computers/devices. If you use public or sharing computers/devices to access our Platform, ensure to log out amongst other safety measures which you may consider exercising while using our Platform.</li>
        <li>Report any security incidents such as suspicious emails, individual password sharing, data leakage or data theft, phishing or malware attacks, hacking attempts etc. to us at <a href="mailto:support@hadaf11.com">support@hadaf11.com</a>.</li>
      </ul>

      <h3>TERMINATION AND EXPIRY OF THE ACCOUNT</h3>
      <ul>
        <li>We reserve the right to modify, terminate or suspend the Services to You at any time, without prior notice, due to any changes in Our internal policy or the Applicable Laws or any breach of these T&Cs by You, or for any reason whatsoever.</li>
        <li>You can exit using the Services and/or terminate Your Account by submitting a request to Us at <a href="mailto:support@hadaf11.com">support@hadaf11.com</a>. We will make every effort to respond to Your request for termination at the earliest. You will remain responsible for all transactions that occurred prior to termination of Your Account.</li>
      </ul>

      <h3>PRIVACY AND SECURITY</h3>
      <ul>
        <li>We will take every reasonable effort not to share any Registration Data or other personal information (collectively, "Personal Information") You provide Us, except as required under any Applicable Law, or to the limited extent necessary for Us to implement any booking instructions We receive from You, or as per Our privacy policy. Please read Our privacy policy for further details regarding the use of Your Personal Information. We will bear no liability for the consequences in any event where Your Personal Information has been willingly or inadvertently shared by You with any third party, even while using our Services.</li>
        <li>You acknowledge that the information made available by You on the Services, may be accessible to certain other users of the Services.</li>
        <li>You hereby expressly consent to receive communications from Us through Your registered phone number and/or Original ID. Your consent to be contacted by Us via phone calls and/or SMS notifications. You agree that any communication so received by You from Us will not amount to spam, unsolicited communication or a violation of Your registration on the national do not call registry.</li>
        <li>You are solely responsible for maintaining the confidentiality of Your Registration Data and will be liable for all activities and transactions that occur through Your Account and the Services, whether initiated by You or any third party. The password and other details of the Account should not be shared with any third party. Your Account is non-transferable and is not capable of being transferred/ sold to a third party. We shall not be liable for any loss that You may incur as a result of someone else using Your password or Account, either with or without Your knowledge.</li>
        <li>It is possible that other users (including unauthorized users or “hackers”) may post or transmit offensive or obscene materials on the Services and that You may be involuntarily exposed to such offensive and obscene materials.</li>
        <li>It is also possible for others to obtain Personal Information about You on the public forum due to Your use of the Services, and that the recipient may use such information to harass or injure You.</li>
        <li>We do not approve of such unauthorized uses, but by using the Services You acknowledge and agree that We shall not be responsible for the use of any Personal Information that You publicly disclose or share with others on the Services. Please carefully select the type of information that You publicly disclose or share with others on the Services, or from the Services on other social platforms (including Facebook etc).</li>
      </ul>

      <h3>NOTICE AND COMMUNICATION</h3>
      <ul>
        <li>Any notice or notification in relation to these T&Cs which You wish to make to Us must be made in writing to ‘<a href="mailto:support@hadaf11.com">support@hadaf11.com</a>’ .</li>
        <li>All Your communication with Us will be of a professional nature only. You will not contact Us to harass, intimidate or threaten any person, or to promote any cause, which You may support.</li>
        <li>You will be subject to Our T&Cs and privacy policy.</li>
      </ul>

      <h3>DISCLAIMERS</h3>

      <ul>
        <li>
          You expressly agree that use of the Service is at your sole risk and is provided on an "as is" basis without warranties of any kind, either express or implied, including, but not limited to, warranties of title or implied warranties of non-infringement, merchantability or fitness for a particular purpose (except only to the extent prohibited under applicable law with any legally required warranty period to the shorter of thirty days from first use or the minimum period required).
        </li>
        <li>
          Without limiting the foregoing, neither Arkimy nor its affiliates or subsidiaries, or any of their directors, employees, agents, attorneys, third-party content providers, distributors, licensees or licensors (collectively, "Arkimy parties") warrant that the service will be uninterrupted or error-free.
        </li>
      </ul>


      <h3>INDEMNITY AND LIMITATION OF LIABILITY</h3>

      <ul>
        <li>
          You will defend, indemnify and hold harmless Arkimy, and each of its affiliates (and its respective employees, directors, agents, advisors, and representatives) from and against any and all claims, costs, losses, damages, judgments, penalties, interest, and expenses (including reasonable attorneys' fees) arising out of any Claim that arises out of or relates to: the use or misuse of Services or any violation of these T&Cs.
        </li>
        <li>
          For purposes hereof: "Claim" means any claim, action, audit, investigation, inquiry, or other proceeding instituted by a person or entity.
        </li>
        <li>
          YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTES WITH US IS THE CANCELLATION OF YOUR ACCOUNT.
          IN NO EVENT SHALL ARKIMY BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THESE T&Cs.
          IN NO EVENT SHALL OUR TOTAL CUMULATIVE LIABILITY TO YOU FOR ANY AND ALL CLAIMS RELATING TO OR ARISING OUT OF YOUR USE OF THE SERVICES, REGARDLESS OF THE FORM OF ACTION, EXCEED INR 10,000/- (INR Ten Thousand Only).
        </li>
      </ul>

      <h3>FORCE MAJEURE</h3>

      <ul>
        <li>
          We shall not be liable for any failure to perform any obligations under this T&Cs, if the performance is prevented, hindered, or delayed by a Force Majeure event, and in such case, its obligations shall be suspended for so long as the Force Majeure Event continues.
        </li>
        <li>
          For the purpose of this clause, “Force Majeure Event” shall mean any event arising due to any cause beyond the reasonable control of Arkimy.
        </li>
      </ul>


      <h3>COPYRIGHT COMPLAINT</h3>

      <p>We respect the intellectual property of others. In case you feel that Your work has been copied in a way that constitutes copyright infringement, You can write to Us at <a href="mailto:support@hadaf11.com">support@hadaf11.com</a>.</p>

      <h3>WAIVER</h3>
      <p>Our failure, delay or omission to exercise or enforce any rights or provisions of these T&Cs will not constitute a waiver of such rights or provisions. A waiver on any one occasion will not be construed as a bar or waiver of any rights or remedies on future occasions and the same for an approval or affirmation. Any claim in relation to Services or T&Cs should be filed within 3 (Three) months from when the cause of action arose. Any claims filed beyond this time period shall be barred.</p>

      <h3>REVISION OF T&Cs</h3>
      <ul>
        <li>We reserve the right to change any of the T&Cs or any policies or guidelines governing the Services, at any time and at Our sole discretion. Any changes will be effective upon posting of the revisions on the Services and We may send You an automated e-mail to Your Original ID informing about the changes made. You can see the date on which these T&Cs were last updated by referring to the legend ‘Last Updated on’ at the beginning of these T&Cs.</li>
        <li>Unless otherwise specified by Us, revised T&Cs will take effect automatically and be binding from the day they are posted on the Services. By continuing to access or use Services, You will be deemed to have agreed to accept and be bound by such revised T&Cs. If You do not agree to the revised T&Cs, You should discontinue accessing and/or using Our Services immediately</li>
        <li>We reserve the right, at Our sole discretion, to change, modify, add or remove portions of these T&Cs, at any time without any prior written notice to You. It is Your responsibility to review these T&Cs periodically for updates/ changes. Your continued use of the Services following the posting of changes will mean that You accept and agree to the revisions. As long as You comply with these T&Cs, We grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use the Services.</li>
      </ul>

      <h3>CUSTOMER GRIEVANCE REDRESSAL POLICY</h3>
      <ul>
        <li>If You are not satisfied with the quality of Service(s) offered or have any other complaints or grievances, please contact Our customer care at <a href="mailto:support@hadaf11.com">support@hadaf11.com</a> .</li>
        <li>The procedure of escalation of complaints shall be as follows:</li>
        <ul>
          <li><a href="mailto:support@hadaf11.com">support@hadaf11.com</a> - Customer Support Executive</li>
          <li><a href="mailto:support@hadaf11.com">support@hadaf11.com</a> - Senior Customer Support Executive</li>
          <li><a href="mailto:support@hadaf11.com">support@hadaf11.com</a> - Customer Support Head</li>
        </ul>
      </ul>

      <h3>SEVERABILITY</h3>
      <p>If any part of these T&Cs is determined to be invalid or unenforceable pursuant to the Applicable Laws then the invalid or unenforceable provisions will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of these T&Cs will continue in effect.</p>

      <h3>ASSIGNMENT</h3>
      <p>Arkimy may assign or delegate these T&Cs and/or the Arkimy Privacy Policy, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any rights or obligations under the T&Cs or Privacy Policy without Arkimy’s prior written consent, and any unauthorized assignment and delegation by you is void and ineffective.</p>

      <h3>SUPPLEMENTAL POLICIES</h3>
      <p>Arkimy may publish additional policies related to specific services such as applications for mobile devices, forums, contests or loyalty programs. Your right to use such services is subject to those specific policies and these T&Cs.</p>


      <h3>ENTIRE AGREEMENT</h3>
      <p>These T&Cs, any supplemental policies and any documents expressly incorporated by reference herein (including Arkimy’s Privacy Policy), contain the entire understanding of you and Arkimy, and supersede all prior understandings of the parties hereto relating to the subject matter hereof, whether electronic, oral or written, or whether established by custom, practice, policy or precedent, between you and Us with respect to the Service.</p>


      <h3>LANGUAGE</h3>
      <p>If we provide you with a translation of the English language version of these Terms, the Arkimy Privacy Policy or any other policy (collectively “Arkimy Policies”), then you agree that the translation is provided for informational purposes only and does not modify the English language version of the original and updated Arkimy Policies.</p>
      <p>In the event of a conflict between a translation of the Arkimy Policies and the English version, the English version of the Arkimy Policies will control.</p>

      <h3>EQUITABLE REMEDIES</h3>
      <ul>
        <li>You acknowledge that the rights granted and obligations made under these T&Cs to Arkimy are of a unique and irreplaceable nature, the loss of which shall irreparably harm Arkimy and which cannot be replaced by monetary damages alone. Accordingly, Arkimy shall be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety or proof of damages) in the event of any breach or anticipatory breach by you.</li>
        <li>You irrevocably waive all rights to seek injunctive or other equitable relief, or to enjoin or restrain the operation of the Service or any Arkimy game, exploitation of any advertising or other materials issued in connection therewith, or exploitation of the Service or any content or other material used or displayed through the Service and agree to limit your claims to claims for monetary damages, limited by Section 7.2 (if any).</li>
      </ul>


      <h3>GOVERNING LAW AND DISPUTE RESOLUTION</h3>
      <ul>
        <li>Any dispute, controversy or claim arising out of or relating to this T&Cs or the validity, interpretation, breach or termination thereof (“Dispute”), including claims seeking redress or asserting rights under the Applicable Law shall be amicably settled through mutual consultation and escalation at such offices of Arkimy as Arkimy may designate. If the Dispute is not settled amicably as aforesaid within a period of 14 (Fourteen) calendar days, the matter would be referred to arbitration in accordance with the provisions of the Arbitration and Conciliation Act, 1996. Arbitration shall be presided over by a sole arbitrator mutually appointed by Us and the Customer.</li>
        <li>The arbitration proceedings shall be conducted in the English language. The venue for the arbitration proceedings shall be Gurugram.</li>
        <li>This T&Cs shall be governed by and construed in accordance with the laws of India and, subject to this Clause, We and You agree and undertake that any controversy or claim arising out of or relating to these T&Cs will be adjudicated exclusively before a competent court in Gurugram, India only.</li>
      </ul>

    </div>



  )
}

export default TnC